import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet-rotatedmarker";
import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";
import { CustomMarker } from "../components/Marker";
import haversine from "haversine-distance";
import { useNavigate, redirect, useParams } from "react-router-dom";
import {
  trailToPolyline,
  mapLocationToArray,
  mapLocationsToArray,
  trailResponse,
  apiDummy,
  flightAPIResponse,
  finalFlightResponse,
} from "../components/API";
import { CookiePopup } from "../components/Cookie2";

const planeMarkerAsset = require("../assets/plane.png");
const helicopterMarkerAsset = require("../assets/helicopter.png");
const helicopterAnimatedMarkerAsset = require("../assets/heli.gif");
const homeMarkerAsset = require("../assets/home.png");
const headerAsset = require("../assets/header.png");
const logoAsset = require("../assets/logo.png");
const emptyAsset = require("../assets/empty.png");

const GREY = "#8A8A8A";

const MapContainerStyle = styled.div`
  width: 100%;
  height: 100%;

  .leaflet-container {
    height: 87%;
    height: calc(100% - 109px);
    width: 100%;
  }

  .blob {
    background: #fffc00;
    border-radius: 50%;
    margin: 10px;
    height: 20px;
    width: 20px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse 2s infinite;
    transform-origin: center;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 252, 0, 0.7);
    }

    70% {
      box-shadow: 0 0 0 20px rgba(255, 252, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 252, 0, 0);
    }
  }
`;

const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 120px;
`;

const Splash = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SplashLogo = styled.img`
  height: 140px;
  text-align: center;
`;

const OverlayContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 5%;
`;

const Overlay = styled.div`
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 6px 0px #00000040;
  padding: 16px;
`;

const Tracker = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 12px;
`;
const Track = styled.div<{ active: boolean }>`
  background: ${(props) => (props.active ? "black" : GREY)};
  height: 3px;
  width: 100%;
`;

const NavBar = styled.div`
  background: black;
  display: flex;
  gap: 30px;
  padding: 12px 0;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-family: "alternate";
  }
`;

export type mapLocation = { lat: number; lng: number };
export type latLngPair = [number, number];

const landedDistance = 500; // how far in metres to count as landed

const landedDestinations: mapLocation[] = [
  // north and west https://www.itilog.com/
  { lat: 51.208165834, lng: -0.136499454 }, // redhill aerodrome
  { lat: 51.653497386, lng: -0.322165378 }, // elstree airfield
];

const helicopterAnimatedMarker = CustomMarker(
  helicopterAnimatedMarkerAsset,
  `leaflet-helicopter-icon`,
  50,
  50
);
const helicopterMarker = CustomMarker(helicopterMarkerAsset, `leaflet-helicopter-icon`, 27, 38);
const helicopterMarkerPulse = CustomMarker(emptyAsset, `blob`, 10, 10);

export function Flight({ time }: { time: number }) {
  const navigate = useNavigate();
  const [trail, setTrail] = useState<any>(finalFlightResponse.trailv2);
  const [latitude, setLatitude] = useState<number>(finalFlightResponse.latitude);
  const [longitude, setLongitude] = useState<number>(finalFlightResponse.longitude);
  const [heading, setHeading] = useState<number>(parseFloat(finalFlightResponse.heading));
  const [speed, setSpeed] = useState<number>(parseFloat(finalFlightResponse.speed));

  const totalTrackers = 4;
  const [activeTrackers, setActiveTrackers] = useState<number>(3);

  const [helicopterAsset, setHelicopterAsset] = useState(helicopterAnimatedMarker);

  const [flash, setFlash] = useState(false);

  const { flightParam } = useParams();

  useEffect(() => {
    if (Date.now() < time) {
      console.log("Not time yet.");
      navigate("/");
    } else {
      // getFlight();
      // window.setInterval(() => {
      //   getFlight();
      // }, 2000 * 1000); // every 2000s
    }
  }, []);

  const RecenterAutomatically = ({ lat, lng }: any) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  };

  const isClose = (lat: number, lng: number) => {
    const helicopter = { latitude: lat, longitude: lng };
    landedDestinations.forEach((location) => {
      const destination = { latitude: location.lat, longitude: location.lng };
      const distance = haversine(helicopter, destination);

      if (distance < landedDistance) {
        setFlash(true);
        setHelicopterAsset(helicopterMarker);
      }
    });
  };

  return trail.length > 0 ? (
    <MapContainerStyle>
      <Header>
        <a
          href="https://g-shock.co.uk/collections/builtdifferent"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Logo src={headerAsset} alt="logo" />
        </a>
      </Header>
      <NavBar>
        <a
          href="https://g-shock.co.uk/collections/all-watches"
          target="_blank"
          rel="noreferrer noopener"
        >
          ALL WATCHES
        </a>
        <a href="https://g-shock.co.uk/collections" target="_blank" rel="noreferrer noopener">
          COLLECTORS
        </a>
        <a
          href="https://g-shock.co.uk/cms/page/view/page_id/10"
          target="_blank"
          rel="noreferrer noopener"
        >
          BRAND
        </a>
      </NavBar>

      <CookiePopup />

      <MapContainer center={[latitude, longitude]} scrollWheelZoom={true} zoom={10}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
          maxZoom={20}
          subdomains="abcd"
        />
        <Marker
          position={{ lat: latitude, lng: longitude }}
          icon={helicopterAsset}
          rotationAngle={heading}
          rotationOrigin="center"
        />

        {flash ? (
          <Marker
            position={{ lat: latitude, lng: longitude }}
            icon={helicopterMarkerPulse}
            rotationAngle={heading}
            rotationOrigin="center"
          />
        ) : null}

        <Polyline positions={trailToPolyline(latitude, longitude, trail)} color="#D3FF54" />
        <RecenterAutomatically lat={latitude} lng={longitude} />
      </MapContainer>
    </MapContainerStyle>
  ) : (
    <Splash>
      <SplashLogo src={logoAsset} alt="logo" />
    </Splash>
  );
}
