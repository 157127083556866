import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Flight } from "./pages/flight";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Email, { time } from "./pages/email";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Email />,
  },
  {
    path: "/flight",
    element: <Flight time={time} />,
  },
  {
    path: "/flight/secret/dev/:flightParam",
    element: <Flight time={0} />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <RouterProvider router={router} />
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
