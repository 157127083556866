import { latLngPair, mapLocation } from "../pages/flight";

export type trailResponse = {
  lat: number;
  lng: number;
  alt: number;
  spd: number; //spped
  ts: number; //timestamp
  hd: number; // ?
}[];

export type flightAPIResponse = {
  heading: string;
  latitude: number;
  longitude: number;
  speed: string;
  trail: trailResponse;
  trailv2?: any;
};

export function trailToPolyline(latitude: number, longitude: number, trail: any): mapLocation[] {
  return [
    { lat: latitude, lng: longitude },
    ...trail.map((trailResponse: any) => {
      return { lat: trailResponse[1], lng: trailResponse[2] };
    }),
  ];
}

export function mapLocationToArray(location: mapLocation): latLngPair {
  return [location.lat, location.lng];
}

export function mapLocationsToArray(locations: mapLocation[]): latLngPair[] {
  return locations.map((location) => mapLocationToArray(location));
}

//dummy api response from flight radar trail command:
export const apiDummy: flightAPIResponse = {
  heading: "187°",
  latitude: 51.2528,
  longitude: -1.2326,
  speed: "10 kts",
  trail: [
    {
      alt: 8000,
      hd: 187,
      lat: 51.257111,
      lng: -1.231719,
      spd: 238,
      ts: 1696444250,
    },
    {
      alt: 8000,
      hd: 184,
      lat: 51.274693,
      lng: -1.228167,
      spd: 238,
      ts: 1696444234,
    },
    {
      alt: 8000,
      hd: 179,
      lat: 51.292255,
      lng: -1.226559,
      spd: 240,
      ts: 1696444218,
    },
    {
      alt: 8000,
      hd: 183,
      lat: 51.296646,
      lng: -1.226684,
      spd: 238,
      ts: 1696444214,
    },
  ],
};

export const finalFlightResponse = {
  _id: {
    $oid: "654f840090c68208ed3b982f",
  },
  flightReg: "G-INTV",
  heading: "183\u00b0",
  latitude: 51.656478,
  longitude: -0.32466,
  speed: "31 kts",
  timestamp: {
    $date: "2023-11-11T13:39:12.884Z",
  },
  trail: [],
  trailv2: [
    [
      0,
      51.235588,
      -0.139095,
      1200,
      118,
      18.1,
      1,
      232,
      {
        alert: 0,
        baro_rate: 232,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        roll: 0.18,
        spi: 0,
        squawk: "3767",
        tas: 278,
        track: 18.11,
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      0.2,
    ],
    [9.72, 51.238031, -0.138498, 1200, 118, 18.1, 0, 352, null, "mlat", null, null, null, 0.2],
    [13.37, 51.240318, -0.138746, 1200, 88, null, 0, 352, null, "mlat", null, null, null, 0.2],
    [22.85, 51.244442, -0.13662, 1200, 92, null, 0, 16, null, "mlat", null, null, null, 0.2],
    [
      32.14,
      51.249689,
      -0.13731,
      1200,
      114,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        roll: 0.18,
        spi: 0,
        squawk: "3767",
        tas: 278,
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      0.2,
    ],
    [35.47, 51.251316, -0.137685, 1100, 116, null, 0, 0, null, "mlat", null, null, null, 0.2],
    [42.34, 51.254177, -0.137813, 1100, 121, null, 0, -304, null, "mlat", null, null, null, 0.2],
    [45.53, 51.256248, -0.138537, 1100, 119, null, 0, -304, null, "mlat", null, null, null, 0.2],
    [
      52.37,
      51.259659,
      -0.138084,
      1100,
      116,
      null,
      0,
      -312,
      {
        alert: 0,
        baro_rate: -312,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "3767",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [56.05, 51.26217, -0.137103, 1200, 118, null, 0, -312, null, "mlat", null, null, null, null],
    [65.85, 51.26744, -0.13336, 1200, 114, null, 0, 304, null, "mlat", null, null, null, null],
    [69.3, 51.267883, -0.136236, 1300, 112, null, 0, 312, null, "mlat", null, null, null, null],
    [
      75.61,
      51.271802,
      -0.137569,
      1300,
      111,
      null,
      0,
      2256,
      {
        alert: 0,
        baro_rate: 2256,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "3767",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [84.74, 51.275794, -0.136599, 1400, 109, null, 0, 2208, null, "mlat", null, null, null, null],
    [91.85, 51.278397, -0.136103, 1400, 99, null, 0, 2408, null, "mlat", null, null, null, null],
    [98.36, 51.28109, -0.137188, 1400, 89, null, 0, 824, null, "mlat", null, null, null, null],
    [
      105.58,
      51.28547,
      -0.137072,
      1400,
      91,
      null,
      0,
      368,
      {
        alert: 0,
        baro_rate: 368,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "3767",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [112.06, 51.288985, -0.140738, 1400, 103, null, 0, 40, null, "mlat", null, null, null, null],
    [118.9, 51.292045, -0.140105, 1400, 105, null, 0, 8, null, "mlat", null, null, null, null],
    [128.57, 51.296988, -0.139843, 1400, 107, null, 0, 0, null, "mlat", null, null, null, null],
    [
      132.93,
      51.300096,
      -0.140825,
      1400,
      115,
      null,
      0,
      0,
      {
        alert: 1,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [142.18, 51.303558, -0.14079, 1400, 114, null, 0, 0, null, "mlat", null, null, null, null],
    [149.44, 51.306943, -0.139915, 1400, 111, null, 0, 0, null, "mlat", null, null, null, null],
    [156.99, 51.312092, -0.137809, 1400, 114, null, 0, 0, null, "mlat", null, null, null, null],
    [
      163.2,
      51.314767,
      -0.13537,
      1400,
      128,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [173.06, 51.318983, -0.132361, 1400, 131, null, 0, 0, null, "mlat", null, null, null, null],
    [180.14, 51.322551, -0.129441, 1400, 111, null, 0, 0, null, "mlat", null, null, null, null],
    [187.29, 51.326838, -0.126387, 1400, 112, null, 0, 0, null, "mlat", null, null, null, null],
    [
      193.56,
      51.329282,
      -0.124656,
      1400,
      103,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 1,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [203.31, 51.334329, -0.120816, 1400, 117, null, 0, 0, null, "mlat", null, null, null, null],
    [210.53, 51.337498, -0.116742, 1400, 117, null, 0, 0, null, "mlat", null, null, null, null],
    [219.9, 51.342441, -0.114079, 1400, 122, null, 0, 0, null, "mlat", null, null, null, null],
    [
      227,
      51.346349,
      -0.110081,
      1400,
      129,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [236.83, 51.348036, -0.10649, 1400, 114, null, 0, 0, null, "mlat", null, null, null, null],
    [243.57, 51.35252, -0.103039, 1400, 106, null, 0, 0, null, "mlat", null, null, null, null],
    [250.78, 51.356938, -0.101388, 1400, 113, null, 0, 0, null, "mlat", null, null, null, null],
    [
      257.33,
      51.358953,
      -0.099793,
      1400,
      104,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [264.33, 51.362463, -0.097356, 1400, 112, null, 0, 0, null, "mlat", null, null, null, null],
    [267.95, 51.364827, -0.095418, 1400, 127, null, 0, 0, null, "mlat", null, null, null, null],
    [278.56, 51.369331, -0.092495, 1400, 120, null, 0, 0, null, "mlat", null, null, null, null],
    [
      286.09,
      51.373848,
      -0.08708,
      1400,
      126,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [295.39, 51.377531, -0.084261, 1400, 122, null, 0, 0, null, "mlat", null, null, null, null],
    [302.71, 51.381003, -0.081335, 1400, 116, null, 0, 0, null, "mlat", null, null, null, null],
    [312.71, 51.385781, -0.077392, 1400, 110, null, 0, 0, null, "mlat", null, null, null, null],
    [
      320.88,
      51.390236,
      -0.073821,
      1400,
      106,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [329.4, 51.393282, -0.071622, 1400, 103, null, 0, 0, null, "mlat", null, null, null, null],
    [336.45, 51.398355, -0.068252, 1400, 121, null, 0, 0, null, "mlat", null, null, null, null],
    [346.02, 51.401978, -0.067263, 1400, 117, null, 0, 0, null, "mlat", null, null, null, null],
    [
      352.41,
      51.405668,
      -0.063559,
      1400,
      125,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [359.93, 51.409603, -0.060849, 1400, 130, null, 0, 0, null, "mlat", null, null, null, null],
    [369.62, 51.413452, -0.05787, 1400, 120, null, 0, 0, null, "mlat", null, null, null, null],
    [378.88, 51.418443, -0.054579, 1400, 112, null, 0, 0, null, "mlat", null, null, null, null],
    [
      384.82,
      51.421032,
      -0.053301,
      1400,
      107,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [391.24, 51.42518, -0.049974, 1400, 112, null, 0, 0, null, "mlat", null, null, null, null],
    [400, 51.428907, -0.04698, 1400, 109, null, 0, 0, null, "mlat", null, null, null, null],
    [408.68, 51.433358, -0.043859, 1400, 122, null, 0, 0, null, "mlat", null, null, null, null],
    [
      416.04,
      51.436039,
      -0.043223,
      1400,
      120,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [422.81, 51.439923, -0.039742, 1400, 121, null, 0, 0, null, "mlat", null, null, null, null],
    [429.68, 51.442593, -0.038284, 1400, 112, null, 0, 0, null, "mlat", null, null, null, null],
    [437.69, 51.44777, -0.035673, 1400, 113, null, 0, 0, null, "mlat", null, null, null, null],
    [
      447.62,
      51.451494,
      -0.033639,
      1400,
      107,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [456.13, 51.456073, -0.030187, 1400, 126, null, 0, 0, null, "mlat", null, null, null, null],
    [464.23, 51.461198, -0.026946, 1400, 130, null, 0, 0, null, "mlat", null, null, null, null],
    [473.42, 51.464356, -0.024646, 1400, 117, null, 0, 0, null, "mlat", null, null, null, null],
    [
      476.7,
      51.467115,
      -0.023561,
      1300,
      121,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [484.42, 51.472071, -0.020617, 1300, 128, null, 0, -320, null, "mlat", null, null, null, null],
    [490.32, 51.474058, -0.019854, 1200, 129, null, 0, -328, null, "mlat", null, null, null, null],
    [499.43, 51.479545, -0.017115, 1200, 128, null, 0, -2128, null, "mlat", null, null, null, null],
    [
      504.99,
      51.481485,
      -0.015764,
      1100,
      113,
      null,
      0,
      -2200,
      {
        alert: 0,
        baro_rate: -2200,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [508.51, 51.483008, -0.014107, 1100, 108, null, 0, -2224, null, "mlat", null, null, null, null],
    [515.85, 51.486145, -0.012446, 1100, 109, null, 0, -1896, null, "mlat", null, null, null, null],
    [522.2, 51.489784, -0.012187, 1000, 97, null, 0, -400, null, "mlat", null, null, null, null],
    [
      531.09,
      51.492741,
      -0.007669,
      1000,
      89,
      null,
      0,
      -352,
      {
        alert: 0,
        baro_rate: -352,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [541, 51.496814, -0.005915, 1000, 70, null, 0, -352, null, "mlat", null, null, null, null],
    [549.62, 51.499921, -0.005343, 1000, 82, null, 0, -352, null, "mlat", null, null, null, null],
    [554.51, 51.500315, -0.005066, 1100, 81, null, 0, -352, null, "mlat", null, null, null, null],
    [
      562.36,
      51.502794,
      -0.003767,
      1100,
      76,
      null,
      0,
      -352,
      {
        alert: 0,
        baro_rate: -352,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [568.84, 51.504951, -0.003985, 1100, 74, null, 0, 0, null, "mlat", null, null, null, null],
    [578.61, 51.508202, -0.006511, 1100, 79, null, 0, 0, null, "mlat", null, null, null, null],
    [585.6, 51.509588, -0.006404, 1100, 55, null, 0, 0, null, "mlat", null, null, null, null],
    [
      595.21,
      51.509362,
      -0.012645,
      1200,
      65,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [602.52, 51.510542, -0.014489, 1200, 62, null, 0, 304, null, "mlat", null, null, null, null],
    [609.73, 51.508405, -0.019185, 1300, 92, null, 0, 312, null, "mlat", null, null, null, null],
    [615.54, 51.509098, -0.023605, 1300, 106, null, 0, 2160, null, "mlat", null, null, null, null],
    [
      623.75,
      51.507483,
      -0.028039,
      1300,
      78,
      null,
      0,
      2272,
      {
        alert: 0,
        baro_rate: 2272,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [631.22, 51.505523, -0.030631, 1300, 97, null, 0, 2144, null, "mlat", null, null, null, null],
    [639.1, 51.50328, -0.031503, 1300, 95, null, 0, 56, null, "mlat", null, null, null, null],
    [649.1, 51.500242, -0.030381, 1300, 102, null, 0, 0, null, "mlat", null, null, null, null],
    [
      658.63,
      51.498385,
      -0.030395,
      1300,
      98,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [667.15, 51.495436, -0.025801, 1300, 93, null, 0, 0, null, "mlat", null, null, null, null],
    [676.06, 51.494099, -0.021418, 1300, 101, null, 0, 0, null, "mlat", null, null, null, null],
    [684.9, 51.492424, -0.01666, 1300, 92, null, 0, 0, null, "mlat", null, null, null, null],
    [
      693.35,
      51.492873,
      -0.013085,
      1300,
      85,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [699.02, 51.494952, -0.009529, 1300, 89, null, 0, 0, null, "mlat", null, null, null, null],
    [707.88, 51.498689, -0.009372, 1300, 131, null, 0, 0, null, "mlat", null, null, null, null],
    [711.07, 51.499088, -0.01251, 1300, 126, null, 0, 0, null, "mlat", null, null, null, null],
    [
      718.21,
      51.499621,
      -0.014583,
      1300,
      129,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [725.76, 51.499974, -0.019941, 1400, 116, null, 0, 344, null, "mlat", null, null, null, null],
    [728.44, 51.499994, -0.022467, 1300, 113, null, 0, 176, null, "mlat", null, null, null, null],
    [736.32, 51.500933, -0.026769, 1300, 136, null, 0, 120, null, "mlat", null, null, null, null],
    [
      742.84,
      51.50139,
      -0.031922,
      1300,
      159,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [748.6, 51.502123, -0.034747, 1200, 158, null, 0, -1304, null, "mlat", null, null, null, null],
    [758.06, 51.501935, -0.041363, 1200, 119, null, 0, -2224, null, "mlat", null, null, null, null],
    [764.3, 51.501567, -0.046704, 1200, 95, null, 0, -2168, null, "mlat", null, null, null, null],
    [
      773.75,
      51.500897,
      -0.05099,
      1200,
      70,
      null,
      0,
      -64,
      {
        alert: 0,
        baro_rate: -64,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [782.7, 51.500281, -0.053402, 1200, 47, null, 0, 0, null, "mlat", null, null, null, null],
    [791.65, 51.500023, -0.054493, 1200, 20, null, 0, 0, null, "mlat", null, null, null, null],
    [794.99, 51.499011, -0.055305, 1100, 20, null, 0, 0, null, "mlat", null, null, null, null],
    [
      803.73,
      51.501354,
      -0.055603,
      1100,
      12,
      null,
      0,
      -312,
      {
        alert: 0,
        baro_rate: -312,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [813.24, 51.500914, -0.055572, 1100, 28, null, 0, -320, null, "mlat", null, null, null, null],
    [822.8, 51.501505, -0.056226, 1100, 30, null, 0, -112, null, "mlat", null, null, null, null],
    [826, 51.502225, -0.055991, 1100, 27, null, 0, -16, null, "mlat", null, null, null, null],
    [
      833.11,
      51.502634,
      -0.056718,
      1200,
      28,
      null,
      0,
      216,
      {
        alert: 0,
        baro_rate: 216,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [840.3, 51.503741, -0.056785, 1200, 28, null, 0, 288, null, "mlat", null, null, null, null],
    [849.39, 51.507165, -0.05809, 1200, 50, null, 0, 328, null, "mlat", null, null, null, null],
    [858.46, 51.508765, -0.060283, 1200, 72, null, 0, 8, null, "mlat", null, null, null, null],
    [
      868.05,
      51.512439,
      -0.06269,
      1200,
      86,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [876.61, 51.514992, -0.065272, 1200, 92, null, 0, 0, null, "mlat", null, null, null, null],
    [886.46, 51.518093, -0.067502, 1100, 85, null, 0, 0, null, "mlat", null, null, null, null],
    [893.15, 51.520035, -0.069115, 1100, 88, null, 0, -304, null, "mlat", null, null, null, null],
    [
      899.54,
      51.521615,
      -0.070463,
      1200,
      82,
      null,
      0,
      -304,
      {
        alert: 0,
        baro_rate: -304,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [908.8, 51.52221, -0.073949, 1200, 67, null, 0, 72, null, "mlat", null, null, null, null],
    [916.31, 51.522218, -0.081158, 1200, 77, null, 0, 304, null, "mlat", null, null, null, null],
    [919.2, 51.522082, -0.081528, 1200, 80, null, 0, 312, null, "mlat", null, null, null, null],
    [
      926.5,
      51.520874,
      -0.084853,
      1200,
      95,
      null,
      0,
      72,
      {
        alert: 0,
        baro_rate: 72,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [929.8, 51.519726, -0.085634, 1100, 122, null, 0, 16, null, "mlat", null, null, null, null],
    [939.31, 51.515867, -0.090767, 1100, 130, null, 0, -296, null, "mlat", null, null, null, null],
    [948.29, 51.51213, -0.093101, 1100, 126, null, 0, -320, null, "mlat", null, null, null, null],
    [
      954.93,
      51.510682,
      -0.091763,
      1100,
      85,
      null,
      0,
      -24,
      {
        alert: 0,
        baro_rate: -24,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [964.31, 51.507543, -0.092039, 1100, 84, null, 0, 0, null, "mlat", null, null, null, null],
    [971.02, 51.505406, -0.093032, 1100, 100, null, 0, 0, null, "mlat", null, null, null, null],
    [978.82, 51.504052, -0.091493, 1100, 65, null, 0, 0, null, "mlat", null, null, null, null],
    [
      988.3,
      51.502271,
      -0.088804,
      1100,
      48,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [997.86, 51.501911, -0.084093, 1100, 56, null, 0, 0, null, "mlat", null, null, null, null],
    [1007.08, 51.503157, -0.081982, 1100, 65, null, 0, 0, null, "mlat", null, null, null, null],
    [1016.75, 51.506012, -0.081625, 1000, 86, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1025.89,
      51.508171,
      -0.084833,
      1000,
      101,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1029.76, 51.508873, -0.087583, 1100, 111, null, 0, 0, null, "mlat", null, null, null, null],
    [1038.59, 51.508904, -0.090742, 1100, 105, null, 0, 0, null, "mlat", null, null, null, null],
    [1047.55, 51.509616, -0.094538, 1100, 97, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1053.67,
      51.509618,
      -0.098415,
      1100,
      104,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1063.6, 51.51107, -0.102309, 1100, 68, null, 0, 0, null, "mlat", null, null, null, null],
    [1072.9, 51.510676, -0.107905, 1100, 68, null, 0, 0, null, "mlat", null, null, null, null],
    [1079.25, 51.510827, -0.109861, 1100, 59, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1085.96,
      51.509366,
      -0.112753,
      1100,
      53,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1089.46, 51.510417, -0.114497, 1100, 49, null, 0, 0, null, "mlat", null, null, null, null],
    [1096.42, 51.508159, -0.117799, 1100, 65, null, 0, 0, null, "mlat", null, null, null, null],
    [1105.8, 51.506645, -0.119771, 1100, 83, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1115.04,
      51.503148,
      -0.123052,
      1100,
      82,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1123.1, 51.501648, -0.121718, 1100, 82, null, 0, 0, null, "mlat", null, null, null, null],
    [1133.1, 51.501435, -0.115926, 1200, 87, null, 0, 320, null, "mlat", null, null, null, null],
    [1142.25, 51.504195, -0.114594, 1100, 113, null, 0, 296, null, "mlat", null, null, null, null],
    [
      1148.31,
      51.505197,
      -0.118165,
      1100,
      89,
      null,
      0,
      32,
      {
        alert: 0,
        baro_rate: 32,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1154.14, 51.504163, -0.119813, 1200, 70, null, 0, -240, null, "mlat", null, null, null, null],
    [1163.85, 51.502154, -0.119457, 1200, 59, null, 0, 80, null, "mlat", null, null, null, null],
    [1166.82, 51.502569, -0.118474, 1100, 42, null, 0, 240, null, "mlat", null, null, null, null],
    [
      1172.95,
      51.504952,
      -0.117433,
      1200,
      15,
      null,
      0,
      48,
      {
        alert: 0,
        baro_rate: 48,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1179.76, 51.50616, -0.117873, 1300, 32, null, 0, 40, null, "mlat", null, null, null, null],
    [1186.66, 51.509079, -0.117282, 1300, 65, null, 0, 2032, null, "mlat", null, null, null, null],
    [1193.73, 51.509927, -0.117462, 1300, 80, null, 0, 2304, null, "mlat", null, null, null, null],
    [
      1203.24,
      51.514179,
      -0.117916,
      1300,
      95,
      null,
      0,
      1496,
      {
        alert: 0,
        baro_rate: 1496,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1206.29, 51.514443, -0.118872, 1400, 89, null, 0, 464, null, "mlat", null, null, null, null],
    [1215.63, 51.51773, -0.12057, 1400, 89, null, 0, 304, null, "mlat", null, null, null, null],
    [1225.01, 51.518807, -0.121486, 1400, 70, null, 0, 240, null, "mlat", null, null, null, null],
    [
      1231.9,
      51.520421,
      -0.125274,
      1400,
      71,
      null,
      0,
      32,
      {
        alert: 0,
        baro_rate: 32,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1235.48, 51.520723, -0.125801, 1400, 69, null, 0, 96, null, "mlat", null, null, null, null],
    [1245.4, 51.52274, -0.130242, 1400, 66, null, 0, 72, null, "mlat", null, null, null, null],
    [1255.07, 51.523568, -0.132443, 1300, 58, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1262.08,
      51.526172,
      -0.132154,
      1400,
      53,
      null,
      0,
      -320,
      {
        alert: 0,
        baro_rate: -320,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1271.59, 51.526969, -0.127225, 1400, 65, null, 0, -16, null, "mlat", null, null, null, null],
    [1275.16, 51.527579, -0.124744, 1400, 88, null, 0, 0, null, "mlat", null, null, null, null],
    [1282.02, 51.527601, -0.122556, 1400, 92, null, 0, 248, null, "mlat", null, null, null, null],
    [
      1289.06,
      51.527177,
      -0.119751,
      1400,
      86,
      null,
      0,
      24,
      {
        alert: 0,
        baro_rate: 24,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1295.91, 51.526893, -0.116712, 1400, 94, null, 0, 0, null, "mlat", null, null, null, null],
    [1302.12, 51.525669, -0.113434, 1500, 86, null, 0, 0, null, "mlat", null, null, null, null],
    [1308.8, 51.523912, -0.111516, 1500, 84, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1315.93,
      51.522289,
      -0.108821,
      1500,
      99,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1325.9, 51.521421, -0.105188, 1500, 71, null, 0, 0, null, "mlat", null, null, null, null],
    [1335.54, 51.521565, -0.102943, 1500, 30, null, 0, 0, null, "mlat", null, null, null, null],
    [1344.63, 51.523488, -0.106977, 1500, 44, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1354.4,
      51.523283,
      -0.110762,
      1500,
      68,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1357.57, 51.522526, -0.111948, 1500, 73, null, 0, 0, null, "mlat", null, null, null, null],
    [1364.5, 51.522481, -0.11294, 1500, 78, null, 0, 0, null, "mlat", null, null, null, null],
    [1371.17, 51.518873, -0.114631, 1500, 92, null, 0, null, null, "mlat", null, null, null, null],
    [
      1375.3,
      51.516973,
      -0.114995,
      1500,
      97,
      null,
      0,
      null,
      {
        alert: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1382.36, 51.514338, -0.114975, 1500, 106, null, 0, null, null, "mlat", null, null, null, null],
    [1385.77, 51.513192, -0.112843, 1400, 120, null, 0, null, null, "mlat", null, null, null, null],
    [1392.77, 51.509585, -0.114734, 1400, 111, null, 0, null, null, "mlat", null, null, null, null],
    [
      1399.35,
      51.507038,
      -0.116268,
      1300,
      91,
      null,
      0,
      -528,
      {
        alert: 0,
        baro_rate: -528,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1408.32, 51.505937, -0.118341, 1300, 78, null, 0, -352, null, "mlat", null, null, null, null],
    [1415.03, 51.503084, -0.119879, 1300, 60, null, 0, -152, null, "mlat", null, null, null, null],
    [1424.37, 51.502007, -0.120149, 1300, 50, null, 0, -40, null, "mlat", null, null, null, null],
    [
      1430.98,
      51.499284,
      -0.121417,
      1300,
      55,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1434.78, 51.499366, -0.123133, 1300, 49, null, 0, 0, null, "mlat", null, null, null, null],
    [1441.62, 51.496545, -0.123424, 1300, 44, null, 0, 0, null, "mlat", null, null, null, null],
    [1446.06, 51.494976, -0.123721, 1300, 64, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1455.43,
      51.493201,
      -0.126196,
      1300,
      66,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1461.45, 51.492512, -0.128918, 1300, 62, null, 0, 0, null, "mlat", null, null, null, null],
    [1470.48, 51.4921, -0.132917, 1300, 65, null, 0, 0, null, "mlat", null, null, null, null],
    [1479.91, 51.489449, -0.133971, 1300, 61, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1485.74,
      51.49208,
      -0.137027,
      1300,
      53,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1492.34, 51.492225, -0.136872, 1200, 38, null, 0, 0, null, "mlat", null, null, null, null],
    [1501.94, 51.49254, -0.137644, 1200, 25, null, 0, -2240, null, "mlat", null, null, null, null],
    [1508.43, 51.492985, -0.1373, 1300, 30, null, 0, -368, null, "mlat", null, null, null, null],
    [
      1515.54,
      51.493735,
      -0.13777,
      1300,
      25,
      null,
      0,
      -40,
      {
        alert: 0,
        baro_rate: -40,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1524.67, 51.494261, -0.136731, 1300, 34, null, 0, 1856, null, "mlat", null, null, null, null],
    [1529.33, 51.495656, -0.135803, 1300, 33, null, 0, 280, null, "mlat", null, null, null, null],
    [1538.42, 51.497593, -0.133902, 1300, 54, null, 0, 16, null, "mlat", null, null, null, null],
    [
      1547.81,
      51.499814,
      -0.131983,
      1300,
      72,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1557.31, 51.503812, -0.133606, 1300, 80, null, 0, 0, null, "mlat", null, null, null, null],
    [1564.77, 51.50564, -0.13624, 1300, 84, null, 0, 0, null, "mlat", null, null, null, null],
    [1567.34, 51.506312, -0.137134, 1300, 93, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1577.36,
      51.504387,
      -0.135913,
      1200,
      114,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1585.64, 51.509125, -0.144445, 1200, 94, null, 0, -2232, null, "mlat", null, null, null, null],
    [1594.59, 51.51065, -0.149608, 1200, 91, null, 0, -2376, null, "mlat", null, null, null, null],
    [1598.81, 51.510321, -0.150966, 1300, 80, null, 0, -568, null, "mlat", null, null, null, null],
    [
      1605.86,
      51.510627,
      -0.153017,
      1300,
      56,
      null,
      0,
      2016,
      {
        alert: 0,
        baro_rate: 2016,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1615.21, 51.509947, -0.154857, 1300, 35, null, 0, 2192, null, "mlat", null, null, null, null],
    [1621.98, 51.510184, -0.159726, 1300, 31, null, 0, 1088, null, "mlat", null, null, null, null],
    [1629.12, 51.50896, -0.157304, 1400, 16, null, 0, 64, null, "mlat", null, null, null, null],
    [
      1637.66,
      51.50834,
      -0.159027,
      1400,
      24,
      null,
      0,
      304,
      {
        alert: 0,
        baro_rate: 304,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1646.91, 51.506365, -0.159885, 1400, 42, null, 0, 304, null, "mlat", null, null, null, null],
    [1653.86, 51.505403, -0.160858, 1400, 52, null, 0, 320, null, "mlat", null, null, null, null],
    [1663.62, 51.503307, -0.15873, 1400, 61, null, 0, -8, null, "mlat", null, null, null, null],
    [
      1667.48,
      51.500715,
      -0.158798,
      1300,
      66,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1676.7, 51.499235, -0.156197, 1300, 90, null, 0, -320, null, "mlat", null, null, null, null],
    [1679.66, 51.498376, -0.156452, 1400, 93, null, 0, -216, null, "mlat", null, null, null, null],
    [1686.49, 51.495977, -0.155454, 1400, 99, null, 0, -8, null, "mlat", null, null, null, null],
    [
      1696.18,
      51.494101,
      -0.150159,
      1400,
      85,
      null,
      0,
      288,
      {
        alert: 0,
        baro_rate: 288,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1705.58, 51.492474, -0.147333, 1400, 82, null, 0, 72, null, "mlat", null, null, null, null],
    [1712.33, 51.49081, -0.143776, 1400, 79, null, 0, 0, null, "mlat", null, null, null, null],
    [1721.54, 51.491571, -0.138922, 1400, 84, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1728.39,
      51.489308,
      -0.134798,
      1400,
      84,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1736.3, 51.490144, -0.130784, 1400, 88, null, 0, 0, null, "mlat", null, null, null, null],
    [1744.2, 51.492357, -0.126954, 1400, 85, null, 0, 0, null, "mlat", null, null, null, null],
    [1749.63, 51.493878, -0.125699, 1400, 93, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1759.19,
      51.496519,
      -0.123616,
      1400,
      92,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1768.65, 51.499188, -0.122006, 1400, 80, null, 0, 0, null, "mlat", null, null, null, null],
    [1778.43, 51.503386, -0.12102, 1400, 90, null, 0, 0, null, "mlat", null, null, null, null],
    [1781.84, 51.504228, -0.120155, 1400, 85, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1791.32,
      51.508534,
      -0.116287,
      1400,
      88,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1793.73, 51.509112, -0.117425, 1300, 90, null, 0, 0, null, "mlat", null, null, null, null],
    [1803.6, 51.512189, -0.113673, 1300, 96, null, 0, -256, null, "mlat", null, null, null, null],
    [1813.08, 51.516065, -0.109348, 1300, 108, null, 0, -304, null, "mlat", null, null, null, null],
    [
      1819.99,
      51.518795,
      -0.106812,
      1300,
      107,
      null,
      0,
      -104,
      {
        alert: 0,
        baro_rate: -104,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1826.79, 51.520827, -0.103088, 1300, 93, null, 0, -16, null, "mlat", null, null, null, null],
    [1833.68, 51.523935, -0.098061, 1300, 104, null, 0, 0, null, "mlat", null, null, null, null],
    [1843.19, 51.525656, -0.094443, 1300, 99, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1850.58,
      51.527571,
      -0.08785,
      1400,
      94,
      null,
      0,
      336,
      {
        alert: 0,
        baro_rate: 336,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1860.1, 51.52986, -0.082214, 1400, 106, null, 0, 304, null, "mlat", null, null, null, null],
    [1866.53, 51.53045, -0.080159, 1400, 104, null, 0, 304, null, "mlat", null, null, null, null],
    [1876.28, 51.534646, -0.075045, 1400, 95, null, 0, 8, null, "mlat", null, null, null, null],
    [
      1882.83,
      51.536628,
      -0.074302,
      1300,
      91,
      null,
      0,
      -304,
      {
        alert: 0,
        baro_rate: -304,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1889.5, 51.538442, -0.07634, 1200, 73, null, 0, -304, null, "mlat", null, null, null, null],
    [1896.4, 51.539762, -0.079564, 1100, 83, null, 0, -2384, null, "mlat", null, null, null, null],
    [
      1905.72,
      51.539861,
      -0.084076,
      1100,
      110,
      null,
      0,
      -2520,
      null,
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [
      1911.86,
      51.53849,
      -0.086316,
      1100,
      121,
      null,
      0,
      -1360,
      {
        alert: 0,
        baro_rate: -1360,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1919.59, 51.5364, -0.088849, 1100, 110, null, 0, -272, null, "mlat", null, null, null, null],
    [1929.15, 51.535385, -0.087908, 1100, 77, null, 0, 0, null, "mlat", null, null, null, null],
    [1935.54, 51.534719, -0.085163, 1000, 89, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1942.47,
      51.534791,
      -0.084386,
      1000,
      77,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1947.65, 51.534193, -0.083595, 1100, 28, null, 0, 0, null, "mlat", null, null, null, null],
    [1952.72, 51.534168, -0.082134, 1100, 63, null, 0, 0, null, "mlat", null, null, null, null],
    [1960.76, 51.534254, -0.080202, 1100, 62, null, 0, 0, null, "mlat", null, null, null, null],
    [
      1970.46,
      51.534513,
      -0.079007,
      1100,
      29,
      null,
      0,
      312,
      {
        alert: 0,
        baro_rate: 312,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [1976.08, 51.53411, -0.077919, 1100, 15, null, 0, 64, null, "mlat", null, null, null, null],
    [1982.52, 51.534572, -0.078099, 1100, 7, null, 0, 0, null, "mlat", null, null, null, null],
    [1992.42, 51.535595, -0.077216, 1100, 22, null, 0, -304, null, "mlat", null, null, null, null],
    [
      1999.05,
      51.535443,
      -0.078393,
      1100,
      24,
      null,
      0,
      -8,
      {
        alert: 0,
        baro_rate: -8,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2008.78, 51.536409, -0.081392, 1100, 76, null, 0, 0, null, "mlat", null, null, null, null],
    [2016.08, 51.534268, -0.080529, 1100, 46, null, 0, 0, null, "mlat", null, null, null, null],
    [2025.08, 51.535905, -0.078983, 1100, 37, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2031.72,
      51.535766,
      -0.081694,
      1200,
      38,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2036.05, 51.535685, -0.084058, 1300, 24, null, 0, 328, null, "mlat", null, null, null, null],
    [2043.11, 51.535915, -0.088801, 1300, 44, null, 0, 2072, null, "mlat", null, null, null, null],
    [2049.66, 51.536996, -0.088519, 1200, 55, null, 0, 2512, null, "mlat", null, null, null, null],
    [
      2052.74,
      51.536878,
      -0.087294,
      1300,
      44,
      null,
      0,
      1064,
      {
        alert: 0,
        baro_rate: 1064,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2060.74, 51.537157, -0.082265, 1300, 46, null, 0, 640, null, "mlat", null, null, null, null],
    [2066.65, 51.536343, -0.078275, 1400, 79, null, 0, 16, null, "mlat", null, null, null, null],
    [2075.99, 51.536757, -0.073432, 1400, 127, null, 0, 1736, null, "mlat", null, null, null, null],
    [
      2081.86,
      51.535955,
      -0.071057,
      1400,
      135,
      null,
      0,
      480,
      {
        alert: 0,
        baro_rate: 480,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2091.4, 51.53803, -0.067226, 1400, 126, null, 0, 224, null, "mlat", null, null, null, null],
    [2095.63, 51.539326, -0.067567, 1300, 108, null, 0, 104, null, "mlat", null, null, null, null],
    [2102.65, 51.540476, -0.071922, 1300, 56, null, 0, -240, null, "mlat", null, null, null, null],
    [
      2105.71,
      51.540717,
      -0.073839,
      1200,
      56,
      null,
      0,
      -280,
      {
        alert: 0,
        baro_rate: -280,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [
      2114.58,
      51.538464,
      -0.079029,
      1100,
      113,
      null,
      0,
      -2480,
      null,
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [
      2120.52,
      51.537255,
      -0.080043,
      1100,
      127,
      null,
      0,
      -2712,
      null,
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2127.06, 51.535389, -0.081099, 1000, 97, null, 0, -2672, null, "mlat", null, null, null, null],
    [
      2136.65,
      51.532628,
      -0.07982,
      1000,
      98,
      null,
      0,
      -2672,
      {
        alert: 0,
        baro_rate: -2672,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2143.7, 51.53173, -0.082007, 1000, 83, null, 0, -2672, null, "mlat", null, null, null, null],
    [2151.85, 51.52932, -0.088133, 1100, 49, null, 0, -2672, null, "mlat", null, null, null, null],
    [2159.72, 51.534324, -0.087558, 1100, 38, null, 0, -2672, null, "mlat", null, null, null, null],
    [
      2166.89,
      51.535742,
      -0.087323,
      1100,
      86,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2176.78, 51.538166, -0.08103, 1100, 122, null, 0, 0, null, "mlat", null, null, null, null],
    [2180.03, 51.537693, -0.078788, 1000, 119, null, 0, 0, null, "mlat", null, null, null, null],
    [2189.68, 51.534725, -0.078972, 1100, 93, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2198.71,
      51.532574,
      -0.081775,
      1100,
      117,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2208.39, 51.530978, -0.083933, 1200, 131, null, 0, 0, null, "mlat", null, null, null, null],
    [2215.14, 51.529415, -0.087234, 1200, 106, null, 0, 336, null, "mlat", null, null, null, null],
    [2222.26, 51.527842, -0.088368, 1300, 88, null, 0, 320, null, "mlat", null, null, null, null],
    [
      2225.33,
      51.527671,
      -0.089027,
      1300,
      76,
      null,
      0,
      1488,
      {
        alert: 0,
        baro_rate: 1488,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2235.21, 51.525663, -0.090985, 1300, 45, null, 0, 2176, null, "mlat", null, null, null, null],
    [2244.98, 51.52465, -0.095091, 1300, 40, null, 0, 520, null, "mlat", null, null, null, null],
    [2254.08, 51.523913, -0.097211, 1300, 41, null, 0, 16, null, "mlat", null, null, null, null],
    [
      2262.53,
      51.52304,
      -0.098496,
      1300,
      38,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2272.45, 51.521262, -0.099211, 1200, 29, null, 0, -2216, null, "mlat", null, null, null, null],
    [2281.99, 51.519837, -0.098531, 1200, 41, null, 0, -2208, null, "mlat", null, null, null, null],
    [2286.36, 51.518861, -0.098963, 1300, 55, null, 0, -2240, null, "mlat", null, null, null, null],
    [
      2290.81,
      51.517999,
      -0.099582,
      1200,
      55,
      null,
      0,
      -1648,
      {
        alert: 0,
        baro_rate: -1648,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2297.53, 51.517318, -0.101424, 1200, 29, null, 0, -152, null, "mlat", null, null, null, null],
    [2306.29, 51.517365, -0.103434, 1200, 44, null, 0, 0, null, "mlat", null, null, null, null],
    [2308.89, 51.517593, -0.103937, 1300, 46, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2318.57,
      51.518616,
      -0.10801,
      1300,
      73,
      null,
      0,
      1928,
      {
        alert: 0,
        baro_rate: 1928,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2324.26, 51.521075, -0.11011, 1300, 98, null, 0, 2192, null, "mlat", null, null, null, null],
    [2333.83, 51.522691, -0.113704, 1300, 111, null, 0, 1536, null, "mlat", null, null, null, null],
    [2337.84, 51.52413, -0.114833, 1300, 98, null, 0, 528, null, "mlat", null, null, null, null],
    [
      2344.71,
      51.526484,
      -0.118657,
      1300,
      105,
      null,
      0,
      16,
      {
        alert: 0,
        baro_rate: 16,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2349.89, 51.528399, -0.121119, 1300, 110, null, 0, 0, null, "mlat", null, null, null, null],
    [2359.76, 51.532085, -0.125593, 1300, 109, null, 0, 0, null, "mlat", null, null, null, null],
    [2368.42, 51.534382, -0.129287, 1300, 108, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2376.08,
      51.53737,
      -0.133555,
      1300,
      104,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2385.71, 51.540347, -0.135091, 1300, 91, null, 0, 0, null, "mlat", null, null, null, null],
    [2392.06, 51.542352, -0.140167, 1300, 90, null, 0, 0, null, "mlat", null, null, null, null],
    [2399.4, 51.546083, -0.142602, 1300, 89, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2408.56,
      51.550282,
      -0.145492,
      1300,
      116,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2414.36, 51.551437, -0.146275, 1300, 93, null, 0, 0, null, "mlat", null, null, null, null],
    [2423.14, 51.556554, -0.147252, 1300, 95, null, 0, 0, null, "mlat", null, null, null, null],
    [2431.33, 51.559873, -0.149642, 1300, 80, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2436.44,
      51.562431,
      -0.150231,
      1300,
      117,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2446.26, 51.567437, -0.152076, 1300, 109, null, 0, 0, null, "mlat", null, null, null, null],
    [2455.3, 51.570502, -0.152641, 1300, 109, null, 0, 0, null, "mlat", null, null, null, null],
    [2465.17, 51.573471, -0.151064, 1300, 89, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2471.41,
      51.575548,
      -0.153371,
      1300,
      74,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2475.85, 51.576439, -0.152821, 1200, 54, null, 0, 0, null, "mlat", null, null, null, null],
    [2484.25, 51.577668, -0.149377, 1200, 44, null, 0, -2192, null, "mlat", null, null, null, null],
    [2490.32, 51.576536, -0.15253, 1200, 39, null, 0, -2192, null, "mlat", null, null, null, null],
    [
      2496.12,
      51.581403,
      -0.153991,
      1100,
      34,
      null,
      0,
      -2232,
      {
        alert: 0,
        baro_rate: -2232,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2502.46, 51.583556, -0.154337, 1200, 33, null, 0, -544, null, "mlat", null, null, null, null],
    [2508.8, 51.584768, -0.157211, 1100, 44, null, 0, -96, null, "mlat", null, null, null, null],
    [2518.45, 51.585102, -0.160765, 1100, 55, null, 0, -80, null, "mlat", null, null, null, null],
    [
      2523.62,
      51.586237,
      -0.162373,
      1200,
      63,
      null,
      0,
      224,
      {
        alert: 0,
        baro_rate: 224,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2532.17, 51.585547, -0.164243, 1200, 79, null, 0, 240, null, "mlat", null, null, null, null],
    [2541.45, 51.585791, -0.169122, 1100, 89, null, 0, 216, null, "mlat", null, null, null, null],
    [2549.2, 51.584739, -0.170598, 1100, 67, null, 0, -328, null, "mlat", null, null, null, null],
    [
      2557.92,
      51.582886,
      -0.171926,
      1100,
      60,
      null,
      0,
      -304,
      {
        alert: 0,
        baro_rate: -304,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2563.64, 51.581592, -0.171631, 1100, 65, null, 0, -152, null, "mlat", null, null, null, null],
    [2573.39, 51.580997, -0.168727, 1100, 64, null, 0, 0, null, "mlat", null, null, null, null],
    [2582.45, 51.581739, -0.161897, 1100, 105, null, 0, 0, null, "mlat", null, null, null, null],
    [
      2590.37,
      51.585505,
      -0.162512,
      1100,
      86,
      null,
      0,
      0,
      {
        alert: 0,
        baro_rate: 0,
        flight: "GINTV   ",
        nic: 0,
        rc: 0,
        spi: 0,
        squawk: "4312",
        type: "mlat",
      },
      "mlat",
      null,
      null,
      null,
      null,
    ],
    [2598.76, 51.588244, -0.163526, 1100, 95, null, 0, 0, null, "mlat", null, null, null, null],
  ],
};
