import { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CookiePopup } from "../components/Cookie2";

const headerAsset = require("../assets/header.png");
const backgroundAsset = require("../assets/background.jpg");

export const time = 1699707000000;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  max-width: 120px;
`;

const TimerContainer = styled.div`
  background: black;
  padding: 20px;
  text-align: center;

  h1 {
    font-family: alternate;
    display: inline;
    color: white;
    text-transform: uppercase;
    font-size: 32px;
    margin-right: 20px;
  }
`;

const Timer = styled.h2`
  display: inline;
  color: red;
  font-family: radioland;
  font-size: 26px;
`;

const BackgroundImg = styled.img`
  position: absolute;
  z-index: -1;
  filter: brightness(0.2);
  height: 100vh;
  width: 100vw;
  object-fit: cover;
`;
const FormContainer = styled.div`
  padding: 40px;
  padding-top: 24px;
  color: white;
  font-size: 14;
  max-width: 600px;
  margin: auto;

  form > fieldset:first-child {
    display: flex;
    gap: 24px;
  }

  fieldset {
  }

  input {
    border: 1px solid white;
    background: transparent;
    margin: 12px 0;
    color: white;
    height: 35px;
  }

  .hs-input {
    width: 100% !important;
  }

  fieldset.form-columns-2 .input {
    margin-right: 0px !important;
  }

  span {
    font-weight: 700;
  }

  .hs-form-field {
  }

  h2 {
    font-weight: 700;
    font-family: alternate;
    font-size: 32px;
  }

  h3 {
    font-size: 16px;
    margin: 24px 0;
    font-weight: 500;
  }

  .legal-consent-container {
    font-size: 12px;
    margin: 12px 0;
  }

  .hs-button {
    margin-top: 24px;
    border-radius: 5px;
    color: black;
    font-size: 23px;
    line-height: 24px;
    font-family: alternate;
    font-weight: normal;
    width: 100%;
    background: white;
    padding: 10px 0;
    height: initial;
    font-kerning: none;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
  }

  .hs-email {
    width: 100% !important;
  }

  .hs_date_of_birth {
    width: 100% !important;
  }
`;

export default function Email() {
  const navigate = useNavigate();

  useEffect(() => {
    if (Date.now() >= time) {
      console.log("Time now.");
      navigate("/flight");
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "2210734",
          formId: "2119d690-f916-4549-bd17-cf4e3fcd7e5b",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <Container>
      <Header>
        <a
          href="https://g-shock.co.uk/collections/builtdifferent"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Logo src={headerAsset} alt="logo" />
        </a>
      </Header>
      <TimerContainer>
        <h1>Event live in:</h1>
        <Timer>{Countdown(time / 1000, 1000)}</Timer>
      </TimerContainer>
      <BackgroundImg src={backgroundAsset} />
      <FormContainer>
        <h2>G-Shock 40th Anniversary </h2>
        <h3>Helicopter Tracker Access Form</h3>
        <div id="hubspotForm"></div>
      </FormContainer>
      <CookiePopup />
    </Container>
  );
}

const calculateDuration = (eventTime: number) =>
  moment.duration(Math.max(eventTime - Math.floor(Date.now() / 1000), 0), "seconds");

function Countdown(eventTime: number, interval: number) {
  const navigate = useNavigate();
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef<any>(0);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
    if (Date.now() >= time) {
      console.log("Time now.");
      navigate("/flight");
    }
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  const pad = (num: number): string => {
    if (num.toString().length === 1) {
      return `0${num}`;
    } else {
      return num.toString();
    }
  };
  return `${pad(duration.days() * 24 + duration.hours())}:${pad(duration.minutes())}:${pad(
    duration.seconds()
  )}`;
}
