import { useEffect, useState, useCallback, useRef } from "react";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
import styled from "styled-components";

const CookieBanner = styled.div`
  padding: 24px;
  background: white;
  font-size: 12px;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 10000;
`;
const CookieButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;
const Accept = styled.div`
  padding: 12px;
  background: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`;
const Decline = styled.div`
  padding: 12px;
  background: white;
  border: 1px solid black;
  color: black;
  border-radius: 5px;
  cursor: pointer;
`;

export function CookiePopup() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "analytics",
    "_ga",
    "_gat",
    "_gid",
    "_ga_HFDFPLSX4Z",
  ]);
  const [showCookies, setShowCookies] = useState(false);

  const SaveAndExit = (analytics: boolean) => {
    if (analytics) {
      ReactGA.initialize("G-HFDFPLSX4Z");
      console.log("Analytics enabled");
    } else {
      removeCookie("_ga", { path: "/" });
      removeCookie("_gat", { path: "/" });
      removeCookie("_gid", { path: "/" });
      removeCookie("_ga_HFDFPLSX4Z", { path: "/" });
      console.log("Analytics disabled");
    }
    setCookie("analytics", analytics);
    setShowCookies(false);
  };

  useEffect(() => {
    if (cookies.analytics === undefined) {
      setShowCookies(true);
    } else {
      SaveAndExit(cookies.analytics);
    }
  }, []);

  return showCookies ? (
    <CookieBanner>
      <p>
        Our site uses cookies. By continuing to use our site, you are agreeing to our{" "}
        <a href="https://g-shock.co.uk/privacy-policy" target="_blank" rel="noreferrer noopener">
          Privacy Policy and Cookie Policy
        </a>
        .
      </p>
      <br />
      <p>
        If you decline, your information won’t be tracked when you visit the website. A single
        cookie will be used in your browser to remember your preference not to be tracked.
      </p>
      <CookieButtonContainer>
        <Accept onClick={() => SaveAndExit(true)}>Accept and close</Accept>
        <Decline onClick={() => SaveAndExit(false)}>Decline</Decline>
      </CookieButtonContainer>
    </CookieBanner>
  ) : null;
}
